import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from '@/store'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [{
            path: "/Login",
            name: "Login",
            component: () =>
                import ('./components/Auth/login'),
            meta: { requiresAuth: false }

        },
        {
            path: "*",
            name: "404pagenotfound",
            props: true,
            component: () =>
                import ("./components/Common/404"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/servererror",
            name: "servererror",
            props: true,
            component: () =>
                import ("./components/Common/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: '/',
            redirect: '/dashboard',
            component: () =>
                import ('@/layouts/Layout'),
            meta: { requiresAuth: true },
            children: [
                // Components

                {
                    name: 'Dashboard',
                    path: '/dashboard',
                    component: () =>
                        import ('./components/Views/Dashboard/basicDashboard'),
                },
                {
                    path: "/banner",
                    name: "banner",
                    props: true,
                    component: () =>
                        import ("./components/Views/Home/banner"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/Country",
                    name: "Country",
                    props: true,
                    component: () =>
                        import ("./components/Views/Home/country"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                // {
                //     path: "/category",
                //     name: "category",
                //     props: true,
                //     component: () =>
                //         import ("./components/Views/Category/category"),
                //     meta: {
                //         requiresAuth: false,
                //     },
                // },
                {
                    path: "/subcategory",
                    name: "subcategory",
                    props: true,
                    component: () =>
                        import ("./components/Views/Subcategory/subcategory"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/plan",
                    name: "subcategoryView",
                    props: true,
                    component: () =>
                        import ("./components/Views/Subcategory/subcategoryView"),
                    meta: {
                        requiresAuth: false,
                    },
                },

                {
                    path: "/Voucher",
                    name: "Voucher",
                    props: true,
                    component: () =>
                        import ("./components/Views/Voucher/VoucherPage.vue"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                
                
                {
                    path: "/userList",
                    name: "userList",
                    props: true,
                    component: () =>
                        import ("./components/Views/User/userList"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/userDetails",
                    name: "userDetails",
                    props: true,
                    component: () =>
                        import ("./components/Views/User/userDetails"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/enrollUsers",
                    name: "enrollUsers",
                    props: true,
                    component: () =>
                        import ("./components/Views/EnrollUsers/enrollUsers"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/Messages",
                    name: "contactUs",
                    props: true,
                    component: () =>
                        import ("./components/Views/ContactUs/contactUs.vue"),
                    meta: {
                        requiresAuth: false,
                    },
                },
            ]
        },

    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
})

router.beforeEach((to, from, next) => {
    if (localStorage.getItem('token')) {
        var newURL = ''
        if (localStorage.getItem('role') == 'employee')
            newURL = "/authenticate";

        else
            newURL = "/authenticate";
        axios({
                method: "GET",
                url: newURL,
                headers: {
                    "token": localStorage.getItem("token")
                }
            })
            .then(response => {
                if (!response.data.status) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("role");
                    store.commit("logoutUser");
                    store.commit("sessionOut", true)
                    next({
                        name: 'Login'
                    })
                    return
                }
            })
    }

    if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
        next({
            name: 'Login'
        })
        return
    }
    if (to.path === '/Login' && store.state.isLoggedIn) {
        next({
            path: '/'
        })
        return
    }
    next()
})

export default router